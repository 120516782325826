<template>

    <div class="top-btns-bar">

        <div v-if="!isList && !hideCreateButton" class="ribbon-button-back" v-router-back>
            <i class="fas fa-arrow-left"></i>
            {{$t("Назад")}}
        </div>

        <div v-else-if="!hideCreate" class="ribbon-button-create" @click="openCreateDlg">
            <i class="fas fa-plus"></i>
            {{$t("Создать")}}
        </div>

        <div class="transparent-buttons">
            <v-btn
                v-for="(item, index) in menu"
                :key="item.Action + index"
                @click="onToolbarButtonClick($event, item)"
                small
                tile
                text
                :disabled="btnIsLock(item.Action)"
            >
                <v-popover :trigger="item.Items ? 'click' : 'hover'">
                    <v-icon left>
                        {{mapIcon(item.Icon)}}
                    </v-icon>
                    {{item.Text}}

                    <template slot="popover">
                        <ul class="ribbon-button-submenu" v-if="item.Items">
                            <li
                                v-for="(itemSub, indexSub) in item.Items"
                                :key="itemSub.Action + indexSub"
                                @click="onToolbarButtonClick($event, itemSub)"
                                v-close-popover
                            >
                                <v-icon left>
                                    {{mapIcon(itemSub.Icon)}}
                                </v-icon>
                                {{itemSub.Text}}   
                            </li>
                        </ul>
                        <span class="ribbon-button-submenu-title">{{ item.ToolTip }}</span>
                    </template>
                    <i class="fas fa-caret-down ribbon-button-has-ch" v-if="item.Items"></i> 
                </v-popover>
            </v-btn>

        </div>

        <slot>
        </slot>

        <CreateDocumentModal v-if="isList" ref="CreateDlg"/>

    </div>

</template>

<script>
import { httpAPI } from "@/api/httpAPI";
import CreateDocumentModal from '@/components/dialogs/CreateDocumentDlg.vue';
import { mapGetters, mapActions } from 'vuex'

export default {
    name: "Toolbar",
    components: {
        CreateDocumentModal
    }, 
    props: {
        menu: {
            type: Array,
            default: null
        },
        isList: {
            type: Boolean,
            default: false
        },
        hideCreateButton: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            icons: {
                document_add: "fas fa-file",
                print: "fas fa-print",
                edit: "fas fa-edit",
                attach_add: "fas fa-paperclip",
                doc_begin_adjustment: "fas fa-share-square",
                sign_send: "fas fa-file-signature",
                doc_register_send: "fas fa-external-link-alt",
                doc_send_link: "fas fa-external-link-alt",
                copy: "fas fa-copy",
                document_link: "fas fa-external-link-square-alt",
                doc_delete: "fas fa-trash",
                exit_editing: "fas fa-arrow-left",
                save: "fas fa-save",
                save_and_close: "fas fa-times-circle",
                doc_set_adjustment: "fas fa-handshake",
                doc_send: "fas fa-paper-plane",
                sign: "fas fa-signature",
                sign_add: "fas fa-file-signature",
                sign_verify: "fas fa-file-contract",
                process_start: "fas fa-project-diagram",
                send_to_view: "fas fa-eye",
                resolution_add: "fas fa-marker",
                doc_register: "fas fa-list-ol",
                doc_reject: "fas fa-undo",
                remove_answer: "fas fa-reply",
                execute_statement: "fas fa-edit",
                doc_register_nonum: "fas fa-file-prescription",
                doc_attach: "fas fa-file-alt",
                doc_take_on_control: "fas fa-stopwatch",
                resolution_prolongate: "fas fa-calendar-alt",
                change_executer: "fas fa-exchange-alt",
                doc_take_off_control: "fas fa-flag-checkered",
                act_item_add: "fas fa-plus-square",
                refresh: "fas fa-sync-alt",
                make_report: "fas fa-search",
                doc_reject_cancel: "fas fa-trash-restore",
                document: "fas fa-file",
                execution_resend: "fas fa-share-square",
                execution_send: "fas fa-check-circle",
                reserve: "fas fa-tasks",
                doc_register_cancel: "fas fa-undo",
                change_control: "fas fa-pen-square",
                prolongate_control: "fas fa-calendar-day",
                add: "fas fa-plus",
                group_add: "fas fa-plus",
                person_add: "fas fa-plus",
                contact_add: "fas fa-plus",
                enterprise: "fas fa-landmark",
                person: "fas fa-user",
                move_to_euol: "fas fa-external-link-alt",
                change_number: "fas fa-exchange-alt",
                change_nomenclature: "fas fa-exchange-alt",
                withdraw_adjustment: "fas fa-comment-slash",
                doc_return: "fas fa-reply-all",
                set_inspected: "fas fa-check",
                accept_invoice: "fa-file-invoice-dollar",
                add_invoice: "fas fa-file-invoice",
                execution_closed: "fas fa-user-check",
                del: "fas fa-undo",
                add_comment: "fas fa-comment",
                make_contract: "fas fa-file-contract",
                contract_send_to_sign: "fas fa-file-signature",
                lockuser: "fas fa-lock",
                unlockuser: "fas fa-lock-open",
                send_eecb: "fas fa-paper-plane",
                disconnect: "fas fa-user-slash",
                delemployee: "fas fa-user-slash",
                reset_pas: "fas fa-cogs",
                move_to_archive: 'fas fa-box',
                move_from_archive: 'fas fa-box-open',
                add_section_answer: 'fas fa-external-link-square-alt',
                CancelExecution: 'fas fa-undo',
                send_exec: 'fas fa-vote-yea',
                edit_recip_list: 'fas fa-unlock-alt'
            }
        }
    },
    computed:{
        ...mapGetters('toolbar', ['isToolbarLock']),
        ...mapGetters('toolbar', ['lockedActions']),
        configuration(){
            return process.env.VUE_APP_CONFIGURATION;
        },
        hideCreate() {
            return this.hideCreateButton || this.configuration == 'C5';
        }
    },    
    methods: {
        ...mapActions('actionsource', ['loadDataSource', 'updateDataSource']),
        ...mapActions(['setOverlayVisible']),
        mapIcon (source) {
            return this.icons[source] ?? "fas fa-ban";
        },
        btnIsLock(btnAction){
            return this.isToolbarLock || this.lockedActions.indexOf(btnAction) > -1;
        },
        async openCreateDlg () {
            try
            {
                let { params } = await this.$refs.CreateDlg.open();
                
                if (params.manually === true) {
                    switch (params.createType)
                    {
                        case 'Documents.ExecuterGroup':
                        {
                            try
                            {
                                let exGroup = await this.$store.dispatch('dialogs/executersGroup/open', { id: null });
                                this.setOverlayVisible({ visible: true, message: 'Сохранение...' });
                                let saveResponse = await httpAPI({
                                    url: `api/actions/save?type=Documents.ExecuterGroup`,
                                    method: 'POST',
                                    headers: { 'Content-Type': 'application/json' },
                                    data: { Content : JSON.stringify({ parent: exGroup, childrens: null }) },
                                });
                                this.setOverlayVisible({ visible: false });

                                if (saveResponse) {
                                    this.$eventBus.$emit('update-data-table');
                                    this.$notify.success(saveResponse.data.message);
                                }
                            }
                            catch(ex)
                            {
                                console.log(ex);
                            }
                            break;
                        }
                        case 'Orders.Counter':
                        {
                            try
                            {
                                let counter = await this.$store.dispatch('dialogs/orderCounter/open', { id: null });
                                this.setOverlayVisible({ visible: true, message: 'Сохранение...' });
                                let saveResponse = await httpAPI({
                                    url: `api/actions/save?type=Orders.Counter`,
                                    method: 'POST',
                                    headers: { 'Content-Type': 'application/json' },
                                    data: { Content : JSON.stringify({ parent: counter, childrens: null }) },
                                });
                                this.setOverlayVisible({ visible: false });

                                if (saveResponse) {
                                    this.$eventBus.$emit('update-data-table');
                                    this.$notify.success(saveResponse.data.message);
                                }
                            }
                            catch(ex)
                            {
                                console.log(ex);
                            }
                            break;
                        }
                        default:
                            break;
                    }
                }
                else {
                    await this.loadDataSource({ createType: params.createType, navigate: true, common: params.isCommon ?? false });

                    if (params.createType === 'CitizenStatements.EuolStatement')
                        this.updateDataSource({ property: 'Data.Object.Document.Card.PerCategory', value: params.PerCategory });
                }
            }
            catch (ex)
            {
                console.log(ex);
            }
        },
        onToolbarButtonClick(event, button) {
            if (!button.Items)
                this.$emit('toolbar-button-click', event, button);
        },
        stickyFPanel() {
            var parent = document.getElementsByClassName('content-box')[0];
            var top_btns_bar_obj = parent.getElementsByClassName('top-btns-bar');
            var white_panel_obj = parent.getElementsByClassName('white-panel-items');
            var tabs_card_btn_obj = parent.getElementsByClassName('tab-selector-panel');
            var after_tabs_card_btn_obj = parent.getElementsByClassName('after-tabs-part-wrapper');

            var toolbar_h = top_btns_bar_obj.length > 0 ? top_btns_bar_obj[0].clientHeight : 0;
            var white_panel_h = white_panel_obj.length > 0 ? white_panel_obj[0].clientHeight : 0;
            var tabs_margin_top = white_panel_obj.length > 0 ? 10 : 0;

            if(white_panel_obj.length > 0){
                white_panel_obj[0].style.top = toolbar_h + "px";
            }
            if(after_tabs_card_btn_obj.length > 0){
                after_tabs_card_btn_obj[0].style.top = (toolbar_h + white_panel_h + tabs_margin_top) + "px";
            }else{
                if(tabs_card_btn_obj.length > 0){
                    tabs_card_btn_obj[0].style.top = (toolbar_h + white_panel_h + tabs_margin_top) + "px";
                }
            }
        }
    },
    mounted() {
        this.$nextTick(this.stickyFPanel);
        window.addEventListener('resize', this.stickyFPanel);
    },
    watch: {
        menu() {
            this.$nextTick(function() {
                this.stickyFPanel();
            });
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.stickyFPanel);
    }
}
</script>