<template>
    <div>
        <v-dialog v-model="visible" transition="dialog-top-transition" scrollable persistent max-width="700">
            <v-card class="modal-maincard">

                <v-toolbar dark flat dense>
                    <v-toolbar-title>{{ title }}</v-toolbar-title>
                </v-toolbar>

                <v-card-text class="wrapperFormModal">
                    <v-card 
                    flat
                    >
                        <v-card-text>
                            <div class="form-box-title">{{ itemsTitle }}</div>
                            <template v-if="flatItems.length === 0">
                                {{ $t('Нет_доступных_элементов') }}
                            </template>
                            <template
                                v-else
                            >
                                <v-list class="modal-select-list" dense outlined>
                                    <v-list-item-group
                                        v-model="selectedItem"
                                        color="cyan darken-1"
                                    >                                        
                                        <v-list-item
                                            v-for="(item, i) in flatItems"
                                            :key="i"
                                            :value="item"
                                            @dblclick="doubleSelect(item)"
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title v-text="item.title"></v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </template>
                        </v-card-text>
                    </v-card>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn 
                    color="cyan" 
                    text 
                    depressed 
                    @click="save" 
                    v-if="selectedItem">
                        {{ $t("Создать") }}
                    </v-btn>

                    <v-btn 
                    color="blue-grey" 
                    text
                    depressed
                    @click="cancel">
                        {{ $t("Отмена") }}
                    </v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import i18n from '@/i18n'
import { mapGetters } from 'vuex';

export default {
    name: "CreateDocumentDialog",
    data () {
        return {
            visible: false,
            resolve: null,
            reject: null,
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },
            selectedItem: null,
            knownDocs: {
                Inner: { title: i18n.t("Внутренний"), params: { createType: "Documents.InnerDocument" } },
                InnerCommon: { title: i18n.t("Общий_внутренний"), params: { createType: "Documents.CommonInnerDocument", isCommon: true } },
                Outgoing: { title: i18n.t("Исходящий"), params: { createType: "Documents.OutgoingDocument" } },
                OutgoingBypassingProject: { title: `${i18n.t("Исходящий")} (${i18n.t("без_возможности_отправки_получателям")})`, params: { createType: "Documents.OutgoingDocument.BypassingProject" } },
                OutgoingCommon: { title: i18n.t("Проект_общего_документа"), params: { createType: "Documents.OutgoingDocument", isCommon: true } },
                Incoming: { title: i18n.t("Входящий"), params: { createType: "Chancellery.IncomingDocument" } },
                ORD: { title: i18n.t("ОРД"), params: {  createType: "Documents.ORD" } },
                Order: { title: i18n.t("Личное_поручение"), params: { createType: "Orders.Order" } },
                CitizenStatement: [
                    { 
                        title: i18n.t("Обращение_физического_лица"),
                        params: { createType: "CitizenStatements.EuolStatement", PerCategory: 1 }
                    },
                    {
                        title: i18n.t("Обращение_юридического_лица"),
                        params: { createType: "CitizenStatements.EuolStatement", PerCategory: 2 }
                    }
                ],
                NPA: { title: i18n.t("Нормативно-правовой_акт"), params: { createType: "Documents.NPA.Document", isCommon: true } },
                Meeting: { title: i18n.t("Совещание"), params: { createType: "None.Meeting" } },
                //ReportQuery: { title: i18n.t("Запрос_на_формирование_отчета"), params: { createType: "Documents.ReportGeneration.Query", isCommon: true } },
            },
            knownDocsDSP: {
                IncomingDSP: { title: i18n.t("Входящий_документ_ДСП"), params: { createType: "Documents.IncomingDocumentDSP" } },
                OutgoingDSP: { title: i18n.t("Исходящий_документ_ДСП"), params: { createType: "Documents.OutgoingDocumentDSP" } },
            },
            knownSettingsObjects: {
                Counter: { title: i18n.t("Журнал_регистрации"), params: { createType: "Chancellery.Counter" } },
                EuolCounter: { title: i18n.t("Журнал_регистрации_обращений"), params: { createType: "CitizenStatements.EuolCounter" } },
                Nomenclature: { title: i18n.t("Номенклатура"), params: { createType: "Chancellery.Nomenclature" } },                
                Contractor: [
                    { 
                        title: `${i18n.t("Контрагент")} (${i18n.t("Юридическое_лицо")})`,
                        params: { createType: "Chancellery.ContractorEnterprise" }
                    },
                    { 
                        title: `${i18n.t("Контрагент")} (${i18n.t("Физическое_лицо")})`,
                        params: { createType: "Chancellery.ContractorPerson" }
                    }
                ],
                ExecuterGroup: { title: i18n.t("Группа_исполнителей"), params: { manually: true, createType: "Documents.ExecuterGroup" } },
                OrderCounter: { title: i18n.t("Счетчик_поручений"), params: { manually: true, createType: "Orders.Counter" } },
            },
        }
    },
    computed: {
        ...mapGetters('auth', ['getUserInfo', 'isCommonServiceEnabled']),
        canCreateDocs() {
            let result = this.getUserInfo.permissions.filter(x => x.startsWith("Create")).map(x => x.replace("Create", ""));

            if (this.getUserInfo.permissions.includes("ChancelleryOutcomingDocumentsList") && this.getUserInfo.permissions.includes("CanSignChancellery"))
                result.push("OutgoingBypassingProject");

            if (result.includes('Outgoing') && this.isCommonServiceEnabled)
                result.push("OutgoingCommon");
            if (result.includes('Inner') && this.isCommonServiceEnabled)
                result.push("InnerCommon");

            return result.filter(x => x.toLowerCase() != "resolution");
        },
        canCreateDocsDSP() {
            let result = this.getUserInfo.permissions.filter(x => x.startsWith("Create")).map(x => x.replace("Create", ""));
            return result.filter(x => x.toLowerCase() != "resolution");
        },
        canCreateSettingsObjects() {
            return this.getUserInfo.permissions.filter(x => x.startsWith("Create")).map(x => x.replace("Create", ""));
        },
        title() {
            switch (this.$route.name) {
                case 'EMS':
                case 'Settings':
                    return this.$t("Создание");
                default:
                    return this.$t("Создание_документа");
            }
        },
        itemsTitle() {
            switch (this.$route.name) {
                case 'EMS':
                case 'Settings':
                    return this.$t("Выберите_тип");
                default:
                    return this.$t("Выберите_тип_документа");
            }
        },
        flatItems() {
            let items = [];
            
            switch (this.$route.name)
            {
                case 'ReportsGeneration':{
                    if (this.getUserInfo.permissions.includes('CreateReportQuery')){
                        items.push({ title: i18n.t("Запрос_на_формирование_отчета"), params: { createType: "Documents.ReportGeneration.Query", isCommon: true } });
                    }
                    break;
                }
                case 'Settings':
                {
                    this.canCreateSettingsObjects.forEach((item) => {
                        if (this.knownSettingsObjects[item]) {

                            if (Array.isArray(this.knownSettingsObjects[item]))
                                this.knownSettingsObjects[item].forEach(item => items.push(item));
                            else
                                items.push(this.knownSettingsObjects[item]);
                        }
                    });
                    break;
                }
                case 'DSP':
                {
                    this.canCreateDocsDSP.forEach((item) => {
                        if (this.knownDocsDSP[item])
                            items.push(this.knownDocsDSP[item]);
                    });
                    break;
                }
                default:
                {
                    this.canCreateDocs.forEach((item) => {
                        if (this.knownDocs[item]) {

                            if (Array.isArray(this.knownDocs[item]))
                                this.knownDocs[item].forEach(item => items.push(item));
                            else
                                items.push(this.knownDocs[item]);
                        }
                    });
                    break;
                }
            }

            return items;
        }
    },
    methods: {
        async open(options = {})
        {
            this.visible = true;
            this.options = Object.assign(this.options, options);

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        cancel() {
            this.visible = false;
            this.reject({
                isCancelled: true,
                message: "Действие_отменено"
            });
        },
        save() {
            this.visible = false;
            this.resolve(this.selectedItem);
        },
        doubleSelect(item) {
            this.visible = false;
            this.resolve(item);
        }
    }
}
</script>